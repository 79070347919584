import React from 'react';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Approach () {
      const imgStyle = {
        maxWidth: '100%',
        maxWeight: '100%',
        objectFit: 'contain'
    };
    return (
        <Container maxWidth="md" sx={{margin: 4}} >
        <Stack direction={{ xs: 'column', /*md: 'row'*/ }} spacing={4}>
          <Stack>
              <Typography variant="h6" component="h2" sx={{fontWeight: 600, textAlign: { xs: 'left', /*md: 'right'*/ } }}>
               APPROACH
              </Typography>
          </Stack>
          <Stack>
              <Typography variant="body1" component="span" sx={{'textAlign': 'left' }}>
              To construct a large-scale dataset containing multiple views of objects in various
              poses and scenes, we leverage the Object Recurrence Prior. Specifically, we begin
              with an extensive unlabelled internet dataset containing 45 million images.
              We detect objects and compute instance retrieval features each object.
              These features are then stored in a database. Using kNN search, we identify clusters
              of objects with high similarity. This process results in a dataset of 4.5 million objects,
              each associated with at least three additional high-quality views.
              This dataset is used for the tasks object insertion and subject-driven generation.
              </Typography>
          </Stack>
        </Stack>
        <Box style={{paddingTop: 16}}>
          <img src='./assets/diagram1.png' style={imgStyle}/>
        </Box>

        <Stack direction={{ xs: 'column', /*md: 'row'*/ }} spacing={4}>
          <Stack>
          </Stack>
          <Stack>
              <Typography variant="body1" component="span" sx={{'textAlign': 'left' }}>
              Having large paired datasets makes object insertion and subject generation simpler.
              To condition the generation on multiple reference images, we train a model to
              take a 2×2 grid of images. This grid includes three reference images alongside
              a noisy target image, which occupies the top-left quarter of the grid.
              The self-attention layers transfer information between the reference images and the noisy
              target image.
              </Typography>
          </Stack>
        </Stack>
        <Box style={{paddingTop: 16}}>
          <img src='./assets/diagram2.png' style={imgStyle}/>
        </Box>
        </Container>
    );
};
export default Approach;