import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { blueGrey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import Abstract from './components/Abstract';
import Approach from './components/Approach';
import SubGen from './components/SubGen';
import ImageAttributions from './components/ImageAttributions';
import ObjectInsert from './components/ObjectInsert';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const font_theme = responsiveFontSizes(createTheme({
  typography: {
    allVariants: {
      fontFamily: [
        'Google Sans',
        'sans-serif',
        'Roboto',
      ].join(','),
    }

  }
}));

const mainTheme = {
  headerColor: blueGrey.A700,
  headerButtonColor: 'white_button',
  headerTextColor: 'white',
  firstColor: '#F3F6F9',
  secondColor: '#FFFFFF',
}

export default function App() {
  const [open, setOpen] = React.useState(false);

  const queryTheme = useTheme();
  const isSmall = useMediaQuery(queryTheme.breakpoints.down('sm'));

  const handleClickOpenAtt = () => {
    setOpen(true);
  };

  const handleCloseAtt = (value) => {
    setOpen(false);
  };

  const nameSpacing = isSmall ? "   " : "        "

  return (
    <React.Fragment>

      <div style={{
        width: '100%',
        zIndex: 1,
        position: null,
      }} >

        <Box style={{
          backgroundColor: mainTheme.headerColor,
          display: 'flex',
          flexDirection: 'column',
          color: mainTheme.headerTextColor,
          textAlign: 'center',
          alignItems: 'center',
          width: '100%',
        }}
        >

          <Container maxWidth="lg">
            <ThemeProvider theme={font_theme}>
              <Typography variant={isSmall ? "h4" : "h3"} sx={{ my: 4, mt: 8, /*color: '#363636',*/ fontWeight: 500, lineHeight: 1.125 }}>
                ObjectMate: A Recurrence Prior for Object Insertion and Subject&#8209;Driven Generation
              </Typography>
            </ThemeProvider>

            <Box style={{ marginBottom: 24, width: '100%' }} >
              <ThemeProvider theme={font_theme}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  <p style={{ whiteSpace: 'pre-wrap', lineHeight: 2, fontWeight: 400, fontSize: isSmall ? 14 : null }}>Daniel&nbsp;Winter<sup>1,2</sup>{nameSpacing}Asaf&nbsp;Shul<sup>1,2</sup>{nameSpacing}Matan&nbsp;Cohen<sup>1</sup>{nameSpacing}<br />Dana&nbsp;Berman<sup>1</sup>{nameSpacing}Yael&nbsp;Pritch<sup>1</sup>{nameSpacing}Alex&nbsp;Rav&#8209;Acha<sup>1</sup>{nameSpacing}Yedid&nbsp;Hoshen<sup>1,2</sup></p>
                  <p style={{ whiteSpace: 'pre-wrap', lineHeight: 2, fontWeight: isSmall ? 400 : 600 }}><sup>1</sup>Google{isSmall ? <br /> : "          "}<sup>2</sup>The&nbsp;Hebrew&nbsp;University of&nbsp;Jerusalem</p>
                </Typography>
              </ThemeProvider>
              <Container sx={{ '& a': { m: 1 } }}>
                <Button href="https://arxiv.org/pdf/2412.08645" variant="outlined" size="large" color={mainTheme.headerButtonColor} sx={{ width: 120 }}> PDF </Button>
                <Button href="https://arxiv.org/abs/2412.08645" variant="outlined" size="large" color={mainTheme.headerButtonColor} sx={{ width: 120 }}> Arxiv </Button>
                <Button style={{textTransform: 'none'}} href="https://objectdrop.github.io" variant="outlined" size="large" color={mainTheme.headerButtonColor} sx={{ width: 120 }}> ObjectDrop </Button>
              </Container>
            </Box>

          </Container>
          <Container maxWidth="md" style={{ marginBottom: 24 }}>
            <video poster="" autoPlay controls muted loop width="100%" style={{ borderRadius: '12px' }}>
              <source src="assets/teaser.mp4" type="video/mp4" />
            </video>
          </Container>

          <Container maxWidth="md">
            <Alert icon={<LightbulbIcon fontSize="inherit" />}  severity="info" style={{
              fontSize: '1rem',
              width: '100%',
              marginBottom: "2rem",
              textAlign: 'left'
            }} >
              <AlertTitle>[TLDR]</AlertTitle>
              <span>We find that large-scale web datasets often repeat the same objects in diverse poses and scenes. Leveraging this, we create a massive supervised dataset for subject-driven generation and object insertion. Our method achieves state-of-the-art identity preservation without requiring test-time fine-tuning.</span>
            </Alert>
          </Container>
        </Box>

        <Box sx={{
          backgroundColor: mainTheme.firstColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        >
          <Abstract />
        </Box>

        <Box sx={{
          backgroundColor: mainTheme.secondColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
        >
          <SubGen />
        </Box>

        <Box sx={{
          backgroundColor: mainTheme.firstColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
        >
          <ObjectInsert />
        </Box>

        <Box sx={{
          backgroundColor: mainTheme.secondColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        >
          <Approach />
        </Box>

        <Box sx={{
          backgroundColor: mainTheme.firstColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
        >
          <Container maxWidth="lg" sx={{ margin: 4 }} >
            <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
              COMPARISON: SUBJECT-DRIVEN GENERATION
            </Typography>
            <img src="assets/subgen/subgen_comp.jpg" width="100%" style={{ marginTop: "2rem" }} />
          </Container>
        </Box>


        <Box sx={{
          backgroundColor: mainTheme.secondColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
        >
          <Container maxWidth="lg" sx={{ margin: 4 }} >
            <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
              COMPARISON: OBJECT INSERTION
            </Typography>
            <Typography>
              Our method better harmonizes the pose and lighting with the scene while preserving object identity.
            </Typography>
            <img src="assets/insertion/insertion_comp.jpg" width="100%" style={{ marginTop: "2rem" }} />
          </Container>
        </Box>


        <Box maxWidth="100%" sx={{
          backgroundColor: mainTheme.firstColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',

        }}
        >
          <Container maxWidth="md" sx={{
            margin: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }} >
            <Stack maxWidth="100%" direction={{ xs: 'column', md: 'row' }} spacing={4}>
              <Stack>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, textAlign: { xs: 'left', md: 'right' } }}>
                  BibTex
                </Typography>
              </Stack>
              <Stack maxWidth="100%" >
                <Container sx={{
                  maxWidth: '100%',
                  padding: 2,
                  backgroundColor: '#f5f5f5',
                  fontFamily: 'monospace',
                  overflowX: 'scroll',
                  whiteSpace: 'pre',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                }} >
                  {String.raw`@misc{winter2024objectmaterecurrencepriorobject,
      title={ObjectMate: A Recurrence Prior for Object Insertion and Subject-Driven Generation}, 
      author={Daniel Winter and Asaf Shul and Matan Cohen and Dana Berman and Yael Pritch and Alex Rav-Acha and Yedid Hoshen},
      year={2024},
      eprint={2412.08645},
      archivePrefix={arXiv},
      primaryClass={cs.CV},
      url={https://arxiv.org/abs/2412.08645}, 
}`}
                </Container>
              </Stack>
            </Stack>
          </Container>
        </Box>

        <Box sx={{
          backgroundColor: blueGrey.A100,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        >
          <Container maxWidth="md" sx={{ margin: 4 }} >
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
              <Stack>
                <Typography variant="body1" component="h2" sx={{ fontWeight: 600, textAlign: { xs: 'left', md: 'right' } }}>
                <p><b>Acknowledgment</b></p>
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="body1" component="span" sx={{ 'textAlign': 'left' }}>

                  <p>We thank owners of images on this site (<Link onClick={handleClickOpenAtt}>link</Link> for attributions) for sharing their valuable assets.<br/>
                  We would like to thank Dani Lischinski, Shmuel Peleg, Amir Hertz, Andrey Voynov, Eliahu Horwitz, Jonathan Kahana, Tal Reiss, Yuval Bahat, and Nadav Magar
                  for their invaluable feedback and discussions.
                  </p>
                </Typography>
                <ImageAttributions open={open} onClose={handleCloseAtt} />
              </Stack>
            </Stack>
          </Container>
        </Box>

      </div>
    </React.Fragment>
  );
}
