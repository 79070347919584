import React from 'react';

import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import HoverImage from './HoverImage';
import Typography from '@mui/material/Typography';
import ClickAlert from './ClickAlert';
import Grid from '@mui/material/Grid';

function ObjectInsert() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const CellWrapper = ({ children }) => (isSmall ?
        <tr>{children}</tr> : <td>{children}</td>
    );

    const imgStyle = {
        maxWidth: '100%',
        maxHeight: '33%',
        objectFit: 'contain',
        borderRadius: '12px',
        verticalAlign: "middle"
    };

    const borderRadiusMap = isSmall ? [
        "12px 12px 0px 0px",
        "0px",
        "0px 0px 12px 12px",
    ] : [
        "12px 0px 0px 12px",
        "0px",
        "0px 12px 12px 0px",
    ];

    const example_list = [7, 8, 1, 2, 3, 4, 5, 6];

    return (
        <Container maxWidth="lg" sx={{ margin: 4 }} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Container maxWidth="md" sx={{ margin: 4 }}  >
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }} style={{textAlign: 'left' }}>
                    OBJECT INSERTION
                </Typography>
                <Typography style={{textAlign: 'left' }}>
                We adapt our Object Recurrence dataset for learning object insertion by using ObjectDrop
                to remove the objects from the target images. We then train ObjectMate on this dataset
                by conditioning its generation on a background image and object reference views.
                ObjectMate achieves photorealistic harmonization of lighting and pose while preserving the object's identity.
                </Typography>
                <ClickAlert />
            </Container>
            {
                isSmall ?
                    <Grid container spacing={1} >
                        <Grid item xs={3} style={{ margin: 'auto' }}>
                            <Typography style={{ fontWeight: 600, margin: 8 }}>Object</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography style={{ fontWeight: 600, margin: 8 }}>Target Image</Typography>
                        </Grid>
                        {example_list.map((item) => (
                            <>
                                <Grid item xs={3} style={{ margin: 'auto' }}>
                                    <table style={{ height: "100%", width: "100%", padding: isSmall ? "0.1em 0.1em" : "1em 0.5em", margin: 0 }}>
                                        <tr>
                                            {[0, 1, 2].map((ref_subidx) => (
                                                <CellWrapper>
                                                    <img src={`./assets/insertion/refs/ref${item}_0${ref_subidx}.jpg`}
                                                        style={{ ...imgStyle, borderRadius: borderRadiusMap[ref_subidx] }} />
                                                </CellWrapper>
                                            ))}
                                        </tr>
                                    </table>
                                </Grid>
                                <Grid item xs={9}>
                                    <HoverImage srcTop={`./assets/insertion/back/img${item}.png`}
                                        srcHover=""
                                        srcClick={`./assets/insertion/result/img${item}.jpg`} />
                                </Grid>
                            </>
                        ))}
                    </Grid>
                    :
                    <Grid container spacing={2} rowSpacing={4}>
                        {example_list.map((item) => (
                            <Grid item xs={6} md={3}>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <td>
                                            <HoverImage srcTop={`./assets/insertion/back/img${item}.png`}
                                                srcHover=""
                                                srcClick={`./assets/insertion/result/img${item}.jpg`} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>

                                            <table style={{ width: "100%", padding: isSmall ? "0.1em 0.1em" : "0.1em 0.5em", margin: 0 }}>
                                                <tr>
                                                    {[0, 1, 2].map((ref_subidx) => (
                                                        <CellWrapper>
                                                            <img src={`./assets/insertion/refs/ref${item}_0${ref_subidx}.jpg`}
                                                                width="100%" style={{ ...imgStyle, borderRadius: borderRadiusMap[ref_subidx] }} />
                                                        </CellWrapper>
                                                    ))}
                                                </tr>
                                            </table>

                                        </td>
                                    </tr>
                                </table>


                            </Grid>
                        ))}
                    </Grid>

            }
        </Container>
    );
};
export default ObjectInsert;