import React from 'react';

import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import { TouchApp } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { blueGrey } from '@mui/material/colors';
import Alert from '@mui/material/Alert';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

import ImageList from '@mui/material/ImageList';
import ImageWithSpinner from './ImageWithSpinner';


const prompts = [
    "in the snow",
    "on a cobblestone street",
    "on a mirror with the pyramids in the background",
    "on the beach",
    "on the surface of moon",
    "with the Eiffel Tower in the background"
  ]
  
  const object_names = [
    "rc car",
    "monster toy",
    "robot toy",
    "stuffed animal",
    "backpack",
    "teapot"
  ]

function SubGen() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  
  const imgStyle = {
    maxWidth: '100%',
    maxWeight: '100%',

    objectFit: 'contain',
    // position: 'absolute',
    borderRadius: '12px',
    verticalAlign: "middle"
  };

  const promptStyle = {
    fontFamily: "Chilanka, cursive",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: isSmall ? "unset" : "1.2rem"
  }

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [imageLoaded1, setImageLoaded1] = React.useState(false);
  const [imageLoaded2, setImageLoaded2] = React.useState(false);
  const [imageLoaded3, setImageLoaded3] = React.useState(false);
  const [imageLoaded4, setImageLoaded4] = React.useState(false);
  const loadedCallbacks = [
    setImageLoaded1, setImageLoaded2, setImageLoaded3, setImageLoaded4
  ];
  const onload = (idx) => {
    switch(idx) {
      case 1:
    }
  }

  const imageLoaded = [
    imageLoaded1, imageLoaded2, imageLoaded3, imageLoaded4
  ];
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index != selectedIndex) {
      setImageLoaded1(false);
      setImageLoaded2(false);
      setImageLoaded3(false);
      setImageLoaded4(false);
    }
    
  };

  const MyList = ({ children }) => (isSmall ?
    <ImageList cols={2}>
      {children}
    </ImageList>
    : <List style={{ height: "100%", marginRight: "1rem", marginLeft: "1rem" }}>
      {children}
    </List>
  );
  const CellWrapper = ({ children }) => (isSmall ?
    <tr>{children}</tr> : <td>{children}</td>
  );

  const object_idinces = [0, 1, 2, 3, 4, 5];
  // const prompt_idinces = [1, 2, 3, 4];

  return (
    <Container maxWidth="lg" sx={{ margin: 4 }} style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Container maxWidth="md" sx={{ margin: 4 }} >
        
        <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }} style={{textAlign: 'left' }}>
          SUBJECT-DRIVEN GENERATION
        </Typography>
        <Typography style={{textAlign: 'left' }}>
        Our method uses the Object Recurrence Prior to train a subject-driven generation model.
        It generates new images of the object that accurately preserve its identity without requiring test-time fine-tuning.
        </Typography>
      </Container>

      <Grid container spacing={2} alignItems="stretch">
        <Grid size={{ xs: 12, sm: 4, md: 4 }} display="grid" alignItems="center" >

              <Alert icon={false} severity="info" style={{
                display: 'block',
                fontSize: '1rem',
                lineHeight: 1.5,
                fontWeight: 500,
                marginRight: isSmall ? "unset" : "1rem",
                marginLeft: isSmall ? "unset" : "1rem"
              }} >
                <TouchApp style={{ marginLeft: 8, marginRight: 8, verticalAlign: 'bottom', }} />
                <span>Click on any reference triplet to see results</span>
              </Alert>
              <MyList>

                {object_idinces.map((ref_idx) => (
                  <>
                    <ListItem size={12} style={{ width: "100%", padding: 0, margin: 0 }} >
                      <ListItemButton style={{ width: "100%", padding: 0, margin: 0 }}
                        selected={selectedIndex === ref_idx}
                        onClick={(event) => handleListItemClick(event, ref_idx)}
                      >
                        <table style={{ width: "100%", padding: isSmall ? "0.1em 0.1em" : "0.5em 0.5em", margin: 0 }}>
                          <tr>
                            {[0, 1, 2].map((ref_subidx) => (
                              <td>
                                <img src={`./assets/subgen/refs/ref0${ref_idx}_0${ref_subidx}.jpg`}
                                  width="100%" style={{
                                    ...imgStyle, opacity: selectedIndex === ref_idx ? 1 : 0.4
                                  }} />
                              </td>
                            ))}
                          </tr>
                        </table>
                      </ListItemButton>

                    </ListItem>
                    {(ref_idx < 3 && !isSmall) ? <Divider /> : null}
                  </>
                ))}

              </MyList>

        </Grid>
        <Grid size={{ xs: 12, sm: 8, md: 8 }}>
          <Grid container spacing={2}>
            {[1, 2, 3, 4].map((item) => (
              <Grid size={6}>
               <ImageWithSpinner  src={`./assets/subgen/results/ref0${selectedIndex}_p${item}.png`} width="100%"
                   hasImageLoaded={imageLoaded[item-1]}
                   onLoad={() => loadedCallbacks[item-1](true)}/>
                <Typography style={{ margin: 2, ...promptStyle }}>
                  a <span style={{ color: blueGrey.A700, fontSize:"1.2em", verticalAlign:"sub"}}>* </span>
                  {prompts[item - 1]}
                </Typography>

              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

    </Container>
  );
};
export default SubGen;