import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function ImageWithSpinner(props) {

    const containerStyle = {
        position: 'relative',
        width: props.width || 'auto',
        height: props.height || 'auto',
    };

    const imgStyle = {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        position: 'relative'
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        aspectRatio: "1/1",
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: props.hasImageLoaded ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
    };

    const spinnerStyle = {
        color: '#fff',
        fontSize: '18px',
    };

    return (
        <div style={containerStyle}>
            <div style={overlayStyle}>
                <CircularProgress style={{'color': 'white'}}/>
            </div>
            <img
                src={props.src}
                style={imgStyle}
                onLoad={props.onLoad}
            />
        </div>
    );
}

export default ImageWithSpinner;