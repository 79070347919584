import React from 'react';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function Abstract () {
    return (
        <Container maxWidth="md" sx={{margin: 4}} >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
          <Stack>
              <Typography variant="h6" component="h2" sx={{fontWeight: 600, textAlign: { xs: 'left', md: 'right' } }}>
                ABSTRACT
              </Typography>
          </Stack>
          <Stack>
              <Typography variant="body1" component="span" sx={{'textAlign': 'left' }}>
              This paper introduces a tuning-free method for both object insertion and subject-driven
              generation. The task involves composing an object, given multiple views, into a scene
              specified by either an image or text. Existing methods struggle to fully meet the task's
              challenging objectives: (i) seamlessly composing the object into the scene with photorealistic
              pose and lighting, and (ii) preserving the object's identity. We hypothesize that achieving
              these goals requires large-scale, but manually collecting sufficient data is simply too
              expensive. The key observation in this paper is that many mass-produced objects recur
              across multiple images of large unlabeled datasets, in different scenes, poses, and lighting
              conditions. We use this observation to create massive supervision by retrieving sets of diverse
              views of the same object. This powerful paired dataset enables us to train a straightforward
              text-to-image diffusion architecture to map the object and scene descriptions to the composited
              image. We compare our method, ObjectMate, with state-of-the-art methods for object insertion
              and subject-driven generation, using a single or multiple references. Empirically, ObjectMate
              achieves superior identity preservation and more photorealistic composition. Differently
              from many other multi-reference methods, ObjectMate does not require slow test-time tuning.
              </Typography>
          </Stack>
        </Stack>
        </Container>
    );
};
export default Abstract;