import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';

const allImage = [
   {
      src: "./assets/attributes/img1.png",
      url: "https://unsplash.com/photos/a-train-traveling-down-train-tracks-next-to-a-train-station-LHaI6xW1uGY"
   },
   {
      src: "./assets/attributes/img2.png",
      url: "https://unsplash.com/photos/a-tunnel-with-a-few-signs-on-it-5tbr9lYtS1k"
   },
   {
      src: "./assets/attributes/img3.png",
      url: "https://unsplash.com/photos/yellow-row-boat-parked-beside-river-VtHYOI0KTIQ"
   },
   {
      src: "./assets/attributes/img4.png",
      url: "https://unsplash.com/photos/brown-ceramic-mug-on-round-table-fkugMBhVf1c"
   },
   {
      src: "./assets/attributes/img5.png",
      url: "https://unsplash.com/photos/a-view-of-a-clock-tower-through-an-arch-C5v5jatp4dI"
   },
   {
      src: "./assets/attributes/img6.png",
      url: "https://unsplash.com/photos/a-table-with-a-cup-and-saucer-on-it-b-W3fNVPv6c"
   },
   {
      src: "./assets/attributes/img7.jpg",
      url: "https://unsplash.com/photos/a-crowd-of-people-standing-around-a-body-of-water-esKtolHZvW8"
   },
   {
      src: "./assets/attributes/img8.jpg",
      url: "https://unsplash.com/photos/a-grassy-field-with-trees-and-a-mountain-in-the-background-YE3E_oslzM4"
   },
   {
      src: "./assets/attributes/img9.jpg",
      url: "https://unsplash.com/photos/a-pool-with-a-pink-flamingos-in-the-middle-of-it-za1ny6YuAgo"
   },
   {
      src: "./assets/attributes/ref8_00.jpg",
      url: "https://unsplash.com/photos/a-statue-of-a-bull-on-a-brick-sidewalk-GXOA82zHjno"
   },
   {
      src: "./assets/attributes/ref7_00.jpg",
      url: "https://unsplash.com/photos/a-yellow-jeep-parked-under-a-tree-in-a-field-iwcLDvUw4u8"
   },
   {
      src: "./assets/attributes/bg1.jpg",
      url: "https://unsplash.com/photos/palm-tree-near-body-of-water-RmEz8lYpiDw"
   },
]

function ImageAttributions(props) {
   const { onClose, open } = props;

   const descriptionElementRef = React.useRef(null);
   React.useEffect(() => {
      if (open) {
         const { current: descriptionElement } = descriptionElementRef;
         if (descriptionElement !== null) {
            descriptionElement.focus();
         }
      }
   }, [open]);


   return (
      <Dialog onClose={onClose} open={open} scroll="paper">
         <DialogTitle>Image Attributions</DialogTitle>
         <DialogContent dividers={true}>
            <DialogContentText tabIndex={-1} ref={descriptionElementRef}>
               <List sx={{ pt: 0 }}>
                  {allImage.map((item) => (
                     <ListItem disableGutters key={item.src} style={{ padding: 0 }}>
                        <img src={item.src} style={{ maxWidth: 128, padding: 8 }} />
                        <Link target="_blank" rel="noopener" href={item.url} variant="body2">{item.url}</Link>
                     </ListItem>
                  ))}
                  <ListItem disableGutters key="dreambench" style={{ padding: 0 }}>
                        <Typography  style={{ maxWidth: 128, padding: 8 }}>DreamBench dataset:</Typography>
                     <Link target="_blank" rel="noopener" href="https://github.com/google/dreambooth/blob/main/dataset/references_and_licenses.txt" variant="body2">https://github.com/google/dreambooth/blob/main/dataset/references_and_licenses.txt</Link>
                  </ListItem>
               </List>
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose}>Close</Button>
         </DialogActions>

      </Dialog>
   );
}

export default ImageAttributions;